<template>
  <nav class="menu-l header_menu_nav menu-r">
    <button @click="toogle" type="button" class="nav-btn" :class="{open: open}" aria-label="Toggle navigation" title="Menu">
      <span class="header-button-label">Serviços</span>
      <span class="header-button-arrow header-button-arrow_nav-toggle"></span>
    </button>
    <div v-if="open" class="main-menu-container">
      <ul class="main-menu">
        <nav-link @click="recalc" label="Recalcular venda dos lotes" icon-type="fa" icon="calculator" icon-style="regular" icon-class="fa-duotone" />
        <nav-link @click="registrarPagamentos" label="Registrar pagamentos dos lotes no financeiro" icon-type="fa" icon="money-check-dollar" icon-style="regular" icon-class="fa-duotone" />
      </ul>
    </div>
  </nav>
</template>

<script>
import NavLink from '../../layout-components/NavLink'
import LeilaoMixin from "@/components/leiloes/components/LeilaoMixin"
import {refazerVendasLotes, registrarPagamentosLotes} from "@/domain/leiloes/services/financeiro"
export default {
  name: 'CobrancaNavMenu',
  mixins: [LeilaoMixin],
  provide: function () {
    return {
      nav: this
    }
  },
  inject: ['cobrador'],
  components: {
    NavLink,
  },
  data () {
    return {
      open: false
    }
  },
  mounted () {
    document.body.addEventListener('click', this.clickOutside, true)
  },
  beforeDestroy () {
    document.body.removeEventListener('click', this.clickOutside)
  },
  methods: {
    toogle () {
      this.open = !this.open
    },
    clickEvent ($event) {
      this.toogle()
    },
    clickOutside (evt) {
      if (
          evt && evt.target && this.$el &&
          (this.$el.contains(evt.target) || (this.$el.parentNode && this.$el.parentNode.contains(evt.target)))
      ) {
        return
      }
      this.open = false
    },
    recalc () {
      this.$uloc.dialog({
        title: 'Recalcular vendas',
        message: 'Esta ação irá recalcular os impostos baseando-se nas configurações atuais do sistema de taxa do leilão, tem certeza?',
        cancel: 'Cancelar',
        ok: 'Sim, prosseguir',
        color: 'black'
      }).then(() => {
        this.$nextTick(() => {
          this.$uloc.loading.show()
          refazerVendasLotes(this.leilao.id)
              .then((response) => {
                this.dg()
                this.cobrador.load && this.cobrador.load()
                this.$uloc.loading.hide()
              })
              .catch(error => {
                this.$uloc.loading.hide()
                this.alertApiError(error)
              })
        })
      }).catch(() => {
      })
    },
    registrarPagamentos () {
      this.$uloc.dialog({
        title: 'Lançar pagamentos',
        message: 'Esta ação irá lançar os pagamenotos registrados individualmente nos lotes no financeiro?',
        cancel: 'Cancelar',
        ok: 'Sim, prosseguir',
        color: 'black'
      }).then(() => {
        this.$nextTick(() => {
          this.$uloc.loading.show()
          registrarPagamentosLotes(this.leilao.id)
              .then((response) => {
                this.dg()
                this.$uloc.loading.hide()
              })
              .catch(error => {
                this.$uloc.loading.hide()
                this.alertApiError(error)
              })
        })
      }).catch(() => {
      })
    }
  }
}
</script>
