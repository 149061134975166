import http from "@/utils/services/http"

export const logBuffer = (action, entity, entityId, date1 = null, date2 = null) => {
    let url = `/api/log/find`
    url = url + `?action=${action}&entity=${entity}&entityId=${entityId}`
    if (date1 && date2) {
        url = url + `&date1=${date1}&date2=${date2}`
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const list = (limit, page, filtros, format = null) => {
    let url = '/api/op/log?page=' + page + '&limit=' + limit + '&' + filtros
    let config = {}
    /*if (format && format !== 'html') {
        config.responseType = 'blob'
        config.timeout = 30000
    }*/
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
